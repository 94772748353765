/* Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-box {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login-box .logo {
    width: 100px;
    margin-bottom: 20px;
  }
  
  .login-box h2 {
    margin-bottom: 10px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-btn {
    background-color: #5a2d9e;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .login-links a {
    display: block;
    margin-top: 10px;
    color: #5a2d9e;
    text-decoration: none;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-box {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .popup-actions {
    margin-top: 10px;
  }
  
  .popup-btn {
    background-color: #5a2d9e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-cancel-btn {
    background-color: gray;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .popup-btn:hover,
  .popup-cancel-btn:hover {
    opacity: 0.9;
  }

  .text-button {
    background: none;
    border: none;
    color: blue;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
  }
  .text-button:hover {
    color: darkblue;
  }
  .text-button:focus {
    outline: none;
  }