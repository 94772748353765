/* client.css */

/* Existing styles */

.app-container {
  display: flex;
  flex-direction: row;
  height: 100%; /* Changed to full viewport height */
  width: 100%;
  background-color: white;
  overflow: hidden; /* Prevent overall page scrolling */
}

.sidebar {
  width: 250px; /* Fixed width for the sidebar */
  padding: 10px;
  height: 100%; /* Ensure the sidebar spans the full viewport height */
  min-width: 250px;
  background-color: #FDF9FF;
  box-sizing: border-box;
  overflow-y: auto; /* Enable vertical scrolling if sidebar content overflows */
}

.main-content {
  flex: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column;
  height: 100%; /* Full viewport height */
  background-color: white;
  overflow: hidden; /* Contain scrolling within .scrollable-content */
}

.username-sticky {
  padding: 20px;
  position: sticky;
  top: 0; /* Stick to the top of the container */
  background-color: white;
  z-index: 10; /* Ensure it stays above other content */
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.scrollable-content {
  flex: 1; /* Take up the remaining space */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px; /* Padding for the scrollable area */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.activity-charts {
  display: flex;
  gap: 70px;
}

/* ... rest of your existing styles ... */

.user-info {
  margin-bottom: 20px;
}

.chart {
  flex: 1;
  max-width: 48%;
  margin: 20px;
}

.activity-feed {
  margin-top: 20px;
}

.feed-item {
  margin-bottom: 20px;
}

/* ... rest of your existing styles ... */


.user-info {
  margin-bottom: 20px;
}

.chart {
  flex: 1; /* Make each chart take equal space */
  max-width: 48%; /* Ensure each chart doesn't take up more than 48% of the width */
  margin: 20px;
}

.feed-item {
  margin-bottom: 20px;
}

.group-list ul {
  list-style-type: none;
  padding-left: 0;
}

.group-list li {
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
}

.group-list li:hover {
  background-color: rgb(213, 186, 236);
}

.group-list li:focus {
  background-color: rgb(213, 186, 236);
}

.user-info h2 {
  font-size: 24px;
  color: #000;
}



