.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);
    border-radius: 0%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    border-radius: 15px;
}

.popup-inner .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
    
}

.input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .input-container input {
    width: 100%;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f3f3f3;
  }
  
  .copy-button {
    padding: 10px 15px;
    border: none;
    background-color: #ddd;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .copy-button:hover {
    background-color: #ccc;
  }

  .group-popup-header {
    display: flex;
    align-items: center; /* Align the image and heading vertically */
    margin-bottom: 10px;
    font-family: SF Pro Display;
    font-size: 20px;
    font-weight: 500;
    line-height: 50px;
    text-align: left;

  }
  
  .profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px; /* Add space between the image and heading */
  }


  .input-container {
    margin-bottom: 15px;
    width: 100%; /* Ensure the input container takes the full width of the popup */
    background-color: #f3f3f3;;
  }
  
  .full-width-input, .full-width-textarea {
    width: 100%; /* Ensure the input and textarea fields take the full width */
    padding: 10px; /*  Add padding for better UX */
    box-sizing: border-box; /* Ensure padding does not affect width */
    border: 1px solid #ccc; /*  Add border styling */
    border-radius: 4px; /*  Add border radius for styling */
  }
  
  textarea.full-width-textarea {
    resize: none; /* Prevent manual resizing of textarea if desired */
  }
  
  .create-group-button{
    background-color: #3C1460; /* Set the desired color */
    color: white; /*  Change text color for better contrast */
    border: none; /*  Remove border */
    padding: 10px 20px; /*  Adjust padding */
    border-radius: 5px; /*  Add border radius for rounded corners */
    cursor: pointer; /*  Change cursor on hover */
    font-size: 17px;/*  Adjust font size */
    font-weight: 500; 
    font-family: SF Pro Display;
    /* Add any other styling you want */
  }

  .heading{
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
     /* Center vertically if needed */
    text-align: center; /* Center text inside the container */
    padding: 20px; /*  Add padding inside the popup */
  
  }



  
  .tabs {
    display: flex;
  }
  
  .tabs button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    font-weight: bold;
  }
  
  .tabs button.active-tab {
    background-color: #6200ea;
    color: white;
  }
  
  .full-width-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .full-width-input:focus {
    border-color: #6200ea;
  }
  
  .delete-group-button,
  .move-users-button {
    padding: 10px 20px;
    background-color: #6200ea;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .delete-group-button:hover,
  .move-users-button:hover {
    background-color: #4500a6;
  }
  