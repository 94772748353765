.container {
  width: -webkit-fill-available;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: hsl(240, 24%, 96%);
  border-radius: 10px;
  padding: 20px;
}

.heading {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #4a4a4a;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
}

.profile-section {
  margin-bottom: 20px;
}

.profile-pic {
  text-align: center;
  margin-bottom: 20px;
}

.pic-placeholder {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #cccccc;
  line-height: 100px;
  text-align: center;
  color: #fff;
  font-size: 0.9em;
}

.input-group {
  margin-bottom: 15px;
}

.input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  background-color: #e9e9e9;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.button {
  background-color: #7335a9;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
