/* styles.css */

.posts-container {
    height: 100vh;
    padding: 20px;
    background-color: #f5e6f7; /* Lightish purplish pinkish background */
    overflow-y: auto;
    max-height: 100%;
  }
  
  .post-tile {
    border: 1px solid #ccc;
    padding: 16px;
    margin: 8px 0;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .post-tile h3 {
    margin: 0 0 8px 0;
    font-size: 1.2em;
  }
  
  .post-tile p {
    margin: 0 0 8px 0;
    font-size: 1em;
  }
  
  .post-tile span {
    font-size: 0.9em;
    color: #666;
  }
  
  h2 {
    margin-top: 24px;
    font-size: 1.5em;
  }
  