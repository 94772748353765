/* Sidebar Layout */
.sidebar-dashboard-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #E6DAED; /* Light lavender background */
}

.main-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 80%;
  background-color: #fff; /* White background */
}

.side-bar {
  background-color: #E6DAED;
  width: fit-content;
  height: 100vh;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}

/* Button Styling */
.nav-bar-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 12px; /* Space between the buttons */
  cursor: pointer;
  transition: color 0.3s ease;
}

.nav-item img {
  width: 24px; /* Adjust icon size */
  height: 24px;
  margin-right: 12px; /* Space between icon and text */
}

/* Default button style */
.nav-item button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: rgba(60, 20, 96, 0.4); /* Light purple text for inactive state */
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

/* Active tab styling */
.nav-item.active button {
  color: rgba(60, 20, 96, 1); /* Darker purple for active text */
}

.nav-item.active img {
  filter: none; /* Original color for active icon */
}

/* Inactive tab styling */
.nav-item:not(.active) button {
  color: rgba(60, 20, 96, 0.4); /* Lighter purple for inactive text */
}

.nav-item:not(.active) img {
  opacity: 0.5; /* Grayscale and darken inactive icons */
}

/* Hover effects */
.nav-item:hover button {
  color: rgba(60, 20, 96, 0.8); /* Darker purple on hover */
}

.nav-item:hover img {
  filter: grayscale(0%); /* Remove grayscale on hover */
  opacity: 0.9; /* Slightly darken icon on hover */
}
