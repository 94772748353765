/* App.css */
.app-layout {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-color: #E6DAED; /* Lighter lavender background */
}


.main-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  background-color: #4c3dfc;
}

#add-group {
  padding: 5px 10px;
  border-radius: 50%;
  background-color: #4c3dfc;
  color: white;
  font-size: 20px;
}
