.invite-popup {
    padding: 20px;
}

.popup-header {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.requests-list {
    list-style-type: none;
    padding: 0;
}

.request-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
}

.request-info {
    display: flex;
    align-items: center;
}

.profile-placeholder {
    width: 50px;
    height: 50px;
    background-color: #e0e0e0;
    border-radius: 50%;
    margin-right: 15px;
}

.request-name {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.request-time {
    font-size: 14px;
    color: #777;
    margin: 0;
}

.request-actions {
    display: flex;
    gap: 10px;
}

.action-button {
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
}

.accept {
    background-color: rgba(115, 53, 169, 0.35);
    color: white;
    border: none;
}

.decline {
    background-color: gray;
    color: white;
    border: none;
}
