.username-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width:300px;
    border-radius: 20px;
}


#pfp-username{
    width: 60px;
    height: 60px;
    border:none;
    border-radius: 25px;
}

#name{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 7px;
    padding: 16px;
    border:none; 
    width: 200px;
}


