

.message-container{
    border: 2px solid black;
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px;
}


#first-line{
    display: flex;
    align-items: center;
}

#first-line .phrase{
    margin-right: 500px;
    flex-shrink: 0;
    white-space: nowrap;
}

#first-line .phrase:last-child{
    margin-right: 0;
}

#reply{
    margin-top: 20px;
    height: 40px;
    width: 120px;
    background-color: rgb(60,20,96);
    color: white;
    border-radius: 20px;

}