.container-notification {
  background-color: rgb(253, 249, 255);
  height: 85.3vh;
  padding-left: 40px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-top-left-radius: 15px;
}

#date {
  margin-bottom: 5px;
  align-self: flex-start;
}

.notifications {
  border: 1px solid green;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
}
