.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-inner {
    position: relative;
    width: 100%;
    max-width: 600px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    box-sizing: border-box;
}

.close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
}

.close-btn:hover {
    color: #555;
}
