.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E6DAED;
  padding: 10px 20px;
  border-radius: 10px;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
}

.title {
  font-size: 24px;
  color: #7335a9;
  margin-left: 10px;
  font-weight: bold;
}

.search-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-input {
  border: 2px solid #7335a9;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 16px;
  color: #7335a9;
  background-color: white;
}

.add-button {
  border: 2px solid #7335a9;
  background-color: #f5f0fa;
  width: 140px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000; /* Initial text color */
  border-radius: 10px;
}

.add:hover {
  background-color: #7335a9;
  color: #fff; /* Change text color to white when hovering */
}
.add {
  border: 2px solid #7335a9;
  background-color: #f5f0fa;
  width: 70px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000; /* Initial text color */
}

.add-button:hover {
  background-color: #7335a9;
  color: #fff; /* Change text color to white when hovering */
}

/* Ensure the .text-style is applied to the button's text */
.text-style {
  font-size: 15px;
  color: inherit; /* Inherit color from .add-button */
}



.plus-icon {
  color: #7335a9;
  font-size: 24px;
  font-weight: bold;
}

.user-icon {
  width: 35px;
  height: 35px;
  background-color: gray;
  border-radius: 50%;
}
