:root {
  --bgrd-clr: rgb(253, 249, 255);
  --btn-clr: rgb(92, 34, 143);
}

.container {
  background-color: var(--bgrd-clr);
  height: 100%;
  width: auto;
  padding: 30px;
  border-top-left-radius: 15px;
  position: relative; /* Adjust for the floating send button */
}

.upper-section {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.mid-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.options {
  background: none; /* Remove button background */
  border: none; /* Remove button border */
  color: inherit; /* Use the inherited text color */
  text-decoration: underline; /* Add underline to the text */
  cursor: pointer; /* Change cursor to pointer for button */
  font-size: 16px; /* Optional: Adjust text size */
  padding-right: 10px; /* Remove padding for text-only appearance */
}


.text-boxes {
  background-color: lightgray;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  color: #333;
}
#text-bar {
  resize: none;
  overflow: auto;
  width: 98%;
  height: 300px;
}

#search-bar {
  width: calc(100% - 40px); /* Adjusts width dynamically */
  height: 40px;
  margin-left: 20px;
  border-radius: 10px;
}

.mid-section {
  margin-bottom: 20px;
  overflow: hidden;
}

#prompt-bar {
  width: 100%;
  height: 150px;
  resize: none;
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
}


.button-section {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
}

.buttons {
  background-color: var(--bgrd-clr);
  height: 100px;
  flex: 1;
  min-width: 180px;
  max-width: 250px;
  border-radius: 10px;
  border: 2px solid var(--btn-clr);
  font-size: large;
  color: var(--btn-clr);
  text-align: left;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttons:hover {
  background-color: #f2f2f2;
}

#send-prompt {
  position: relative;
  width: calc(100% - 60px);
  margin-top: 40px;
  height: 60px;
  background-color: var(--btn-clr);
  color: white;
  border: none;
  border-radius: 20px;
  font-size: medium;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#send-prompt:hover {
  background-color: rgb(60, 20, 96);
}

.dropdown {
  background-color: white;
  border: 1px solid #ddd;
  list-style-type: none;
  padding: 0;
  margin-left: 50px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  border-radius: 10%;
  z-index: 1;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

button {
  padding: 10px 20px;
 
}

.prompt-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f0fb;
  padding: 10px;
  border-radius: 8px;
  gap: 10px;
}

.prompt-button,
.notification-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  text-align: left;
  font-family: Arial, sans-serif;
}

.prompt-button .icon,
.notification-button .icon {
  font-size: 24px;
  margin-right: 10px;
}

.prompt-button .button-text h4,
.notification-button .button-text h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.prompt-button .button-text p,
.notification-button .button-text p {
  margin: 0;
  font-size: 12px;
  color: #555555;
}

.prompt-button.active,
.notification-button.active {
  background-color: rgba(115, 53, 169, 0.35);
  color: #ffffff;
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.prompt-button:hover,
.notification-button:hover {

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #ead5fa;
}
