.reply-post-tile {
    background-color: rgba(115, 53, 169, 0.15); /* Light purplish color */
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reply-post-tile:hover {
    background-color: rgba(115, 53, 169, 0.25); /* Darker on hover */
  }
  
  .reply-post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .reply-post-date {
    font-size: 0.85rem;
    color: #000; /* Slightly darker purple */
  }
  
  .reply-post-tile h4 {
    margin: 0;
  }
  
  .reply-post-tile p {
    margin-top: 5px;
    color: #000; /* Darker purple for the response text */
  }
  