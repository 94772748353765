.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    border-radius: 12px;
    padding: 20px;
    width: 400px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .popup-body {
    margin-top: 20px;
  }
  
  .option {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .option label {
    margin-left: 10px;
    font-weight: bold;
  }
  
  .schedule-options {
    margin-top: 10px;
  }
  
  .time-input,
  .timezone-dropdown {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .popup-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .save-button {
    background-color: #5c229f;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
  }
  
  .save-button:hover {
    background-color: #441777;
  }
  